@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;600;700&display=swap");

.game-who-wants-score {
  .who-wants-score-page {
    background-image: radial-gradient(circle at 50% 34%, #f7a700, #d35400 122%);
  }

  .games-page-circle {
    img {
      max-width: 122px;
      width: 100%;
    }
  }

  .wrapper-point-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;

    @media screen and (max-width: 991px) {
      gap: 20px;
      padding: 30px 0;
      justify-content: center;
    }

    .wrapper-point {
      position: relative;
      margin: 60px 0;

      @media screen and (max-width: 991px) {
        margin: 20px 0;
      }

      .point {
        filter: grayscale(100%);
        max-width: 122px;
        width: 100%;
        position: relative;
        z-index: 1;

        &.active {
          filter: grayscale(0%);
        }
      }

      .point-area {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #000;
        font-weight: 600;
        font-size: 15px;
        line-height: 1.3;
        z-index: 2;

        span.point-text {
          font-size: 30px;
          margin: 10px 0;
        }
      }

      .gift {
        position: absolute;
        top: -50px;
        left: 0;
        width: 100%;
        z-index: 0;
        max-width: 88px;
      }

      .box {
        position: absolute;
        width: 100%;
        left: -36px;
        bottom: -29px;
        min-width: 190px;
        z-index: 0;
      }
    }
  }
}

// .wrapper-icon-contaniner {
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-start;
//   background-color: #cbd5e1; // slate-300
//   margin-top: 3rem; // mt-12
//   width: 100%;
//   height: 16px;
//   position: relative;
//   border-radius: 50px;
// }

// .icon-container {
//   filter: grayscale(100%);
//   width: 32px; // w-8
//   height: 32px; // h-8
//   background-color: #ffffff;
//   border-radius: 50%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   color: #64748b; // text-[#64748b]
//   margin: -6px auto 0;
// }

// .icon {
//   height: 20px; // h-5
//   width: 20px; // w-5
// }
