.forum-page {
  .global-grid-component {

    .global-grid-page-title {
      h1 {
        font-size: 16px;
        line-height: 21px;
        color: #383838;
      }

      p {
        font-size: 12px;
        line-height: 18px;
        color: #383838;
      }
    }

    .terms-of-use {
      a {
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        svg {
          color: #777;
        }

        span {
          margin-left: 6px;
          color: #383838;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .forum-old {
      .forum-content {
        border: 1px #54ae4b solid;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
        padding: 35px 26px 23px 33px;

        p {
          margin-bottom: 0;
        }
      }
    }

    .forum {
      margin-top: 3rem;
      margin-bottom: 3rem;

      &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1rem;

        .forum-message-title {
          border: 5px #d0d0d0 solid;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          font-size: 25px;
          font-weight: bold;
          width: 70px;
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 13px;
        }

        .forum-message-content {
          .forum-message-user {
            color: #383838;
            font-size: 18px;
            font-weight: bold;
            margin: 0 0 8px 0;
          }

          .forum-message-date {
            color: #d0d0d0;
            margin: 0;
          }
        }
      }

      &-content {
        padding-top: 14px;

        > p {
          color: #383838;
          font-weight: 600;
          padding-right: 20px;
        }

        .forum-actions {
          text-align: right;

          .forum-likes {
            color: #f05a57;
            margin-right: 20px;
            font-weight: bold;

            span {
              margin-left: 4px;
              color: #666;
            }
          }

          .forum-messages {
            color: #54ae4b;
            font-weight: bold;

            span {
              margin-left: 4px;
            }
          }
        }
      }
    }
  }

  .conversation .conversation-send-message {
    border-top: 0;
  }

  .forum-comments {

    .forum-content {
      background-color: #666666;
      color: #fff;
      padding: 30px;
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      border-radius: 16px;
      margin-left: 83px;

      p {
        color: #fff;
        margin: 0;
      }
    }
  }
}

.message-box-actions {
  .emoji-picker-react {
    position: fixed !important;
    right: auto !important;
    top: auto !important;
  }
}

.detail-page-categories ul li {
  padding: 0 20px 0 0;
  margin-bottom: 20px;
}

.magazine-modal .modal-dialog.modal-lg .modal-content .modal-header {
  padding-left: 2rem;
  padding-top: 2rem;
}