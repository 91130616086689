.home-product {
  .home-brand {
    margin-bottom: 60px;
  }

  .swiper-slide {
    margin-bottom: 40px;
  }

  .swiper-button-next, .swiper-button-prev {
    top: 50%;
    margin-top: -30px;
    width: 11px;
    height: 16px;
  }
}

.global-grid-component.product-component {
  .global-grid-item {
    opacity: 1;
    padding: 0;
    overflow: initial;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    //max-width: 200px;
    border: 1px #ececec solid;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;

    &:hover {
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
      opacity: 1;
      border-color: #55b24b;

      .global-grid-item-image {
        filter: none;
      }
    }

    .global-grid-item-name {
      margin-bottom: 20px;
    }

  }
}

.home-brand {
  .global-grid-item-description {
    text-align: center;
  }
}
