@import "./base/_variables.scss";
@import "./base/_global.scss";
@import "./base/_fonts.scss";
@import "./base/_ie.scss";

html,
body,
.public-container {
  /* IE 10-11 didn't like using min-height */
  height: 100%;
}

.public-container {
  display: flex;
  flex-direction: column;

  .content {
    flex: 1 0 auto;
    /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
    padding: 20px;

    @media screen and (max-width: 767px) {
      padding: 10px;
    }

    .public-container-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      h1 {
        font-size: 24px;
        line-height: 30px;
        margin-top: 1rem;
      }

      h2 {
        font-size: 16px;
        line-height: 22px;
        margin-top: 1rem;
      }
    }

    .login-page {
      display: flex;
      flex-direction: column;
      justify-content: center;

      form {
        margin-top: 80px;

        @media screen and (max-width: 767px) {
          margin-top: 0;
        }
      }

      .text-success {
        color: #54ae4b !important;
        font-size: 14px;
      }

      .btn {
        width: 100%;
        font-size: 14px;
      }
    }
  }

  .footer {
    flex-shrink: 0;
    /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
    padding: 20px;

    > div.container {
      padding-top: 90px;
      padding-bottom: 70px;

      h3 {
        font-size: 12px;
        color: #666;
      }

      //Social Networks
      ul.social-networks {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;

        > li {
          list-style: none;
          margin: 0 20px 0 0;
          padding: 0;

          &:last-child {
            margin-right: 0;
          }

          a {
            svg {
              font-size: 24px;
              color: #666666;
            }

            &:hover {
              svg {
                color: #bfbfbf;
              }
            }
          }
        }
      }

      .footer-contact {
        display: flex;
        flex-direction: column;
        width: 100%;

        > h3 {
          margin-top: 40px;
          margin-bottom: 20px;
        }

        > a {
          font-size: 12px;
          margin-bottom: 20px;
          color: #666666;
          font-weight: 600;

          &:hover {
            color: #bfbfbf;
          }
        }
      }

      //Footer Menu
      ul.footer-menu {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        > li {
          margin: 0;
          padding: 0;
          list-style: none;
          color: #666666;

          a {
            color: #666666;
            margin-bottom: 10px;
            display: block;
            font-weight: 600;

            &:hover {
              color: #bfbfbf;

              > svg {
                color: #bfbfbf;
              }
            }

            > span {
              font-size: 12px;
              line-height: 24px;
              vertical-align: top;
            }

            svg {
              margin-left: 10px;
              color: #666666;
              //color: #bfbfbf;
              font-size: 24px;
              width: 30px;
            }
          }
        }
      }
    }
  }
}

.btn.btn-warning {
  background-color: orange;
  border-color: orange;

  &:hover {
    color: #fff;
    border-color: orange;
    background-color: orange;
  }
}

.btn.btn-success {
  background-color: #54ae4b;
  border-color: #54ae4b;
}

.btn.btn-outline-success {
  color: #54ae4b;
  border-color: #54ae4b;

  &:hover {
    color: #fff;
    border-color: #54ae4b;
    background-color: #54ae4b;
  }
}

.signup-page {
  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .signup-checkbox {
      padding-left: 10px;
      > input {
        margin-top: 5px;
      }

      label {
        a {
          color: #54ae4b;
        }
      }
    }
    .signup-buttons {
      margin-top: 1rem;

      @media screen and (max-width: 767px) {
        flex-direction: column !important;

        > .w-50 {
          margin-top: 15px;
        }
      }
    }

    div.w-50 {
      padding-left: 10px;
      padding-right: 10px;

      @media screen and (max-width: 767px) {
        width: 100% !important;
      }
    }

    > .form-group {
      width: 50%;
      padding-left: 10px;
      padding-right: 10px;

      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 0;
      }
    }
  }
}

.btn {
  height: 50px !important;
  line-height: 50px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
}

.form-group {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="date"],
  select {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0 20px !important;
  }
}

/* Header */
.header {
  &-content {
    display: flex;
    flex-direction: row;
    width: 100%;

    &-logo {
      flex: 1;
      > img {
        max-width: 74px;
        width: 100%;
      }
    }

    .wrapper-profile {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .profile-icon {
        font-size: 14px;
        margin-left: 20px;
        color: #666;
        cursor: pointer;

        @media screen and (max-width: 767px) {
          display: none;
        }

        &:last-child {
          margin-left: 0;
        }
      }

      .user-avatar {
        width: auto !important;
        height: 50px !important;
        margin-left: 20px;

        > img {
          width: auto !important;
          height: 50px !important;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
        }
      }
    }

    .navbar {
      .navbar-collapse.collapse {
        margin-top: 1rem;
      }

      .nav-link {
        font-size: 12px;
      }

      .nav-item.dropdown {
        .dropdown-item {
          font-size: 12px;
        }
      }
    }

    .navbar-expand-lg .navbar-collapse .navbar-nav {
      > .nav-link {
        padding-left: 1rem;
        padding-right: 1rem;

        &:last-child {
          padding-right: 0;
        }
      }

      > .nav-item.dropdown {
        padding-left: 1rem;
        padding-right: 1rem;

        > .nav-link {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .dropdown-toggle::after {
      margin-left: 1em;
      vertical-align: 0.2em;
      border-top: 0.5em solid;
      border-right: 0.5em solid transparent;
      border-bottom: 0;
      border-left: 0.5em solid transparent;
      display: none;
    }
  }
}

#user-avatar {
  background-color: transparent;
  border: none;
  margin: 0 0 0 20px;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 50px;

  &:visited,
  &:hover,
  &:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  @media screen and (max-width: 991px) {
    margin-left: 0 !important;
  }
}

.header-content-logo {
  .navbar-brand {
    @media screen and (max-width: 991px) {
      margin: 0 auto;
    }
  }
}

#user-avatar ~ .dropdown-menu {
  left: auto;
  right: 0 !important;
}

#user-avatar:not(:disabled):not(.disabled):active:focus,
#user-avatar:not(:disabled):not(.disabled).active:focus,
.show > #user-avatar.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
/* End Header */

/* Homepage Tabs */
.nav.nav-tabs {
  font-size: 12px;
  color: #666;
  position: relative;

  > a {
    margin-bottom: 0;
    padding: 5px 0;
    font-size: 12px;
    color: #d0d0d0;
    border: none;
    border-bottom: 2px #d0d0d0 solid;
    position: sticky;
    left: auto;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;

    &.active {
      font-size: 12px;
      color: #666;
      border-bottom-color: #666;
    }
  }
}

/* End Homepage Tabs*/

/* Global Grid Component */
.global-grid-component {
  .global-grid-component-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
    margin-bottom: 6rem;

    h3 {
      font-size: 16px;
    }

    a {
      font-size: 12px;
      color: #d0d0d0;

      &:hover {
        color: #666;
      }
    }
  }

  .global-grid-item {
    color: #383838;
    text-align: center;
    opacity: 1;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    border: 1px #fff solid;
    padding: 20px 15px;
    //max-width: 178px;
    min-height: 210px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;

    > a {
      overflow: hidden;
      -webkit-border-top-left-radius: 20px;
      -moz-border-top-left-radius: 20px;
      border-top-left-radius: 20px;
      -webkit-border-top-right-radius: 20px;
      -moz-border-top-right-radius: 20px;
      border-top-right-radius: 20px;
    }

    &:hover {
      opacity: 1;
      border-color: #55b24b;

      .global-grid-item-action {
        .add-to-cart-default {
          background-color: #55b24b;
        }
      }

      // Global Grid Image
      .global-grid-item-image {
        filter: none;
      }
    }

    // Global Grid Image
    .global-grid-item-image {
      width: auto;
      max-height: 160px;
    }

    .global-grid-item-image-wrapper {
      height: 215px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    // Global Grid None Image
    .global-grid-none-image {
      background-color: #383838;
      height: 215px;
      width: 100%;
    }

    /* Product Content */
    .global-grid-item-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
      padding-left: 15px;
      padding-right: 15px;
      margin: 1rem 0 1rem 0;
      position: relative;

      .global-grid-item-name {
        color: #666;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
      }

      .global-grid-item-price {
        color: #666;
        font-weight: bold;

        > span {
          font-size: 28px;
          line-height: 34px;
          margin-right: 6px;
        }

        > svg {
          font-size: 24px;
          line-height: 34px;
        }
      }

      .global-grid-item-rate {
        margin-top: 6px;

        > svg {
          path {
            fill: #d0d0d0;
          }
        }

        > svg.active {
          path {
            fill: #debc27;
          }
        }
      }

      .global-grid-item-description {
        font-size: 12px;
        line-height: 16px;
        margin: 0;
        display: -webkit-box;
        //max-width: 200px;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    a {
      display: block;
      color: #383838;
      width: 100%;
      height: 100%;

      &:hover {
        color: #383838;
      }

      p {
        margin-top: 10px;
        margin-bottom: 0;
      }
    }

    .global-grid-item-action {
      width: calc(100% + 2px);
      margin-bottom: -1px;
      -webkit-border-bottom-left-radius: 20px;
      -moz-border-bottom-left-radius: 20px;
      border-bottom-left-radius: 20px;
      -webkit-border-bottom-right-radius: 20px;
      -moz-border-bottom-right-radius: 20px;
      border-bottom-right-radius: 20px;
      overflow: hidden;

      > a {
        display: block;
        padding: 11px 15px 13px 15px;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        text-align: left;

        > svg {
          margin-right: 10px;
        }
      }

      a.add-to-cart-default {
        color: #fff;
        background-color: #d0d0d0;
      }

      a.add-to-cart-success {
        color: #55b24b;
        background-color: transparent;
        -webkit-animation: fadeinout 4s linear forwards;
        animation: fadeinout 4s linear forwards;
      }

      a.add-to-cart-danger {
        background-color: #bc450f;
      }
    }
  }
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
/* End Global Grid Component */

.header-content-logo.navbar-brand {
  margin: 0;
  padding: 0;

  @media screen and (max-width: 991px) {
    text-align: center;
  }
}

/* Swiper Defaults */
.swiper-container {
  padding: 0 50px;
}

.swiper {
  padding-bottom: 50px;
}

.swiper-button-prev,
.swiper-button-next {
  z-index: 999 !important;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  pointer-events: auto;
}

.swiper-button-prev {
  background-image: url("../../assets/images/arrow-prev.png") !important;
  background-repeat: no-repeat;
  left: 0 !important;
}

.swiper-button-next {
  background-image: url("../../assets/images/arrow-next.png") !important;
  background-repeat: no-repeat;
  right: 0 !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  content: "" !important;
}
/* End Swiper Defaults */

.dropdown-menu.show {
  a {
    font-size: 12px;
    line-height: 1.2;
    padding: 10px 20px;
  }
}

/* Need Login Global */
.page-container {
  display: flex;
  flex-direction: column;

  .page-content {
    flex: 1 0 auto;
  }

  .footer {
    flex-shrink: 0;
  }
}

// Breadcrumb
.wrapper-breadcrumb {
  .breadcrumb {
    background-color: transparent;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;

    .breadcrumb-item {
      > a {
        font-size: 14px;
        color: #d0d0d0;
      }

      &:before {
        color: #d1d1d1;
        content: ">";
      }

      &:first-child {
        &:before {
          display: none;
        }
      }

      &.active {
        font-size: 14px;
        color: #666666;
      }
    }
  }
}

// Accordion
.accordion {
  background: transparent;
  overflow: hidden !important;

  .card {
    border: none;
    background: transparent;

    .card-header {
      border: none;
      background: transparent;
      padding-left: 0;

      .btn.btn-link {
        width: 100%;
        text-align: left;
        vertical-align: middle;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #424242;
        font-size: 16px;
        padding: 0;
        text-decoration: none;

        &:hover {
          color: #424242;
          text-decoration: none;
        }

        &:focus {
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          text-decoration: none;
        }
      }
    }

    .card-body {
      padding-left: 0;
      padding-right: 0;
      font-size: 14px;
    }

    .collapse {
      padding-left: 1rem;
      padding-right: 1rem;
      opacity: 0.75;

      .card-body {
        padding-left: 0;
        padding-right: 0;
        font-size: 14px;

        ul.accordion-sub-menu {
          display: none !important;
        }
      }

      &.show {
        .card-body {
          ul.accordion-sub-menu {
            list-style: none;
            margin: 0;
            padding: 0;
            display: block !important;

            li {
              list-style: none;
              margin: 0 0 25px 0;
              padding: 0;

              &:last-child {
                margin-bottom: 0;
              }

              a {
                color: #424242;
                font-size: 14px;
                text-decoration: none;
              }

              &:hover {
                > a {
                  color: #000;
                  text-decoration: none;
                }
              }

              &.active {
                > a {
                  color: #55b24b;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.global-select {
  height: 50px !important;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  border-color: #d0d0d0;
  padding-left: 20px;
  padding-right: 20px;
}

.form-control:focus {
  border-color: inherit !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.private-header-top {
  p {
    margin: 0;
    font-size: 13px;
  }
}

/* Detail Pages Top Accordion */
.detail-pages-top-accordion {
  border: 2px #d8d8d8 solid;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 30px 20px 30px 40px;

  .accordion {
    .card {
      .card-header {
        margin: 0;
        padding: 0;

        .btn {
          margin-left: 0;
          padding-left: 0;
          font-size: 16px;
          color: #383838;
        }
      }

      .card-body {
        color: #666666;
        font-size: 12px;
      }

      .collapse {
        padding: 0;
      }
    }
  }
}
/* End Detail Pages Top Accordion */

/* Detail Page Categories */
.detail-page-categories {
  h3 {
    color: #383838;
    font-weight: bold;
    font-size: 16px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      padding: 20px 0;
      position: relative;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      a {
        color: #474747;
        font-size: 14px;
      }

      &.active,
      &:hover {
        &:after {
          content: "";
          background: url(../../assets/images/green-arrow.png) no-repeat;
          width: 8px;
          height: 11px;
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -5px;
        }

        a {
          color: #54ae4b;
        }
      }
    }
  }
}
/* End Detail Page Categories */

// Static Page
.static-page {
  h1 {
    font-size: 20px;
    margin-bottom: 5rem;
  }

  .static-content {
    h3 {
      color: #666;
      font-size: 16px;
      line-height: 1.2;
      margin: 0 0 2rem 0;
      padding: 0;
      font-weight: bold;
    }

    p {
      font-size: 12px;
      line-height: 18px;
      margin: 0 0 20px 0;
    }

    &-bottom {
      padding: 20px 0;
      margin-bottom: 20px;

      a {
        display: block;
        margin-bottom: 20px;
      }

      p {
        margin: 0;
        padding-left: 10px;
      }
    }
  }
}

// Faq Page
.faq-page {
  h1 {
    font-size: 20px;
    margin-bottom: 5rem;
  }

  h3 {
    font-weight: bold;
  }

  .faq-item {
    margin-bottom: 4rem;

    h3 {
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 0;
    }
  }
}

// Invalid Feedback Message
.invalid-feedback {
  font-size: 14px !important;
}

// Emoji Style
aside.emoji-picker-react {
  position: absolute !important;
  right: 0;
  top: 42px;
}

.emoji-picker-react .emoji {
  padding: 5px 0;
}

.emoji-categories {
  padding: 10px !important;
}

//.swiper-slide {
//  margin-bottom: 0 !important;
//}

.cursor-pointer {
  cursor: pointer;
}

// Shopping Cart Current Count
.shopping-cart-count {
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 999;
  background: #dc3545;
  color: #fff;
  font-size: 12px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-container {
  .w-50 {
    width: 50% !important;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 20px;

    .w-50 {
      width: 95% !important;
    }
  }
}

.search-popup {
  top: calc(100% + 0.5rem); /* inputun hemen altına yerleştirmek için */
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1000;
  border: 1px solid #fff;
}

.search-popup a {
  display: block;
  padding: 0.5rem 1rem;
  transition: background-color 0.2s ease;
  color: #474747;
}

.search-popup a:hover {
  background-color: #f1f1f1;
}
