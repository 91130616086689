.wheel-page {
  background-image: radial-gradient(circle at 50% 34%, #f62a6d, #4b3190 122%);
  position: relative;
  height: 100%;

  &-title {
    color: #fff;
    font-size: 36px;
  }

  &-desc {
    color: #fff;
    font-size: 14px;
  }

  .wheel-spin {
    margin-top: 48px;
    position: relative;

    .sc-gsTCUz.bhdLno {
      margin: 0 auto;
    }

    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;

      > button.btn.btn-success {
        width: 136px !important;
        height: 136px !important;
        -webkit-border-radius: 50% !important;
        -moz-border-radius: 50% !important;
        border-radius: 50% !important;
        margin: 0 !important;
        padding: 0 !important;
        border: 2px #fff solid;
        box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.53);

        font-size: 16px;
        font-weight: 700;
        line-height: 1.5 !important;
        color: #fff;

        &:disabled {
          opacity: 1 !important;
          background-color: #cccccc;

          &:hover {
            background-color: #cccccc;
          }
        }

        &:hover {
          background-color: #218838;
        }

        @media screen and (max-width: 460px) {
          width: 80px !important;
          height: 80px !important;
          font-size: 14px;
        }

        @media screen and (max-width: 360px) {
          width: 60px !important;
          height: 60px !important;
          font-size: 13px;
        }
      }
    }
  }

  .game-timer {
    max-width: 128px;
    width: 100%;
    min-height: 260px;
    margin: 0;
    padding: 24px 20px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.16);
    position: absolute;
    top: 24px;
    left: 24px;
    color: #fff;

    @media screen and (max-width: 900px) {
      text-align: center;
      position: static;
      max-width: 90%;
      margin-bottom: 30px;
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }

    p {
      font-size: 36px;
      font-weight: 500;
      margin: 0;
    }

    .game-countdown {
      font-size: 32px;
      margin-top: 24px;
      margin-bottom: 24px;

      > div {
        margin: 0 auto;
      }
    }
  }

  .game-size {
    font-size: 36px !important;
  }

  .games-page-question {
    color: #fff;

    h3 {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      margin: 0 0 4px 0;
    }

    form {
      .form-group {
        > label {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 20px;
        }
      }

      .form-check {
        label {
          font-size: 16px;
        }
      }
    }
  }
}

// Transforms: Rotates
.wheel-spin {
  transform: rotate(-45deg);
}

.wheel-page .wheel-spin-button > button.btn {
  transform: rotate(45deg);
}

// Triangle
.wheel-spin .gTLZXx {
  transform: rotate(45deg) !important;
}

.games-page-introduction {
  padding: 50px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .games-page-circle {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    width: 215px;
    height: 215px;
    background-color: #fff;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .btn.btn-success {
    padding: 16px 48px;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    border-radius: 8px !important;
    background-color: #54ae4b;
    border-color: #54ae4b;

    &:hover {
      background-color: #28a745;
      border-color: #28a745;
    }
  }

  .wrapper-reward {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: auto;
    width: 100%;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    opacity: 0;

    &.active {
      opacity: 1;
    }

    h3 {
      color: #fff;
      font-weight: bold;
      font-size: 16px;
      margin: 0 0 10px 0;
      padding: 0;
    }

    // Girls Point
    h4 {
      color: #fba629;
      font-weight: bold;
      font-size: 48px;
      margin: -40px 0 0 0;
      padding: 0;
      position: absolute;
    }
  }

}

.wheelWin {
  max-width: 0 !important;
  -webkit-transition: max-width 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: max-width 1s ease-in-out;
  
  &.active {
    max-width: 50% !important;

    @media screen and (max-width: 767px) {
      max-width: 100% !important;
      margin-top: 30px;
    }
  }
}

.questionWheelWin {
  height: 0;
  -webkit-transition: height 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: height 1s ease-in-out;

  .wrapper-reward {
    img {
      display: none;
    }
  }

  &.active {
    height: 100% !important;

    .wrapper-reward {
      img {
        display: block;
      }
    }
  }
}

.control {
  font-family: "Lexend", sans-serif !important;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 16px;
}
  .control input {
      position: absolute;
      z-index: -1;
      opacity: 0;
  }
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border: 0px solid #000000;
  border-radius: undefinedpx;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #cccccc;
}

.control input:checked ~ .control_indicator {
  background: #2aa1c0;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: red;
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-radio .control_indicator {
  border-radius: 50%;
}

.control-radio .control_indicator:after {
  left: 2px;
  top: 2px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ffffff;
  transition: background 250ms;
}
.control-radio input:disabled ~ .control_indicator:after {
  background: #7b7b7b;
}.control-radio .control_indicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: -1.3rem;
  margin-top: -1.3rem;
  background: #2aa1c0;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}
@keyframes s-ripple {
  0% {
      opacity: 0;
      transform: scale(0);
  }
  20% {
      transform: scale(1);
  }
  100% {
      opacity: 0.01;
      transform: scale(1);
  }
}
@keyframes s-ripple-dup {
 0% {
     transform: scale(0);
  }
 30% {
      transform: scale(1);
  }
  60% {
      transform: scale(1);
  }
  100% {
      opacity: 0;
      transform: scale(1);
  }
}
.control-radio input + .control_indicator::before {
  animation: s-ripple 250ms ease-out;
}
.control-radio input:checked + .control_indicator::before {
  animation-name: s-ripple-dup;
}

.wheel-page {
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
      position: relative;
      padding-left: 50px;
      cursor: pointer;
      line-height: 32px;
      display: inline-block;
      color: #fff;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 32px;
      height: 32px;
      border: 2px solid #fff;
      border-radius: 100%;
      background: transparent;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 20px;
      height: 20px;
      background: #fff;
      position: absolute;
      top: 6px;
      left: 6px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
  }

  .mb-3.col-sm-10 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
  
    .form-check {
      margin-bottom: 10px;
      padding-left: 0;
      width: 50%;
    }
  }
}