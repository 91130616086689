.wrapper-mainslider-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: left;

  h1 {
    font-size: 38px;
    line-height: 50px;
    color: #214a79;
    font-weight: bold;
  }

  .btn.btn-default {
    color: #fff;
    background-color: #d0d0d0;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    display: block;
    width: 100%;
  }

}

.home-swiper {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;

  .swiper-slide {
    margin-bottom: 0;
  }

  .swiper-wrapper {
    align-items: center;
  }
}