.brands-page {
  margin-top: 50px;

  .product-brand-item-img-wrapper {
    padding-top: 20px;

    img {
      max-width: 132px;
    }
  }

  .product-brand {
    height: 880px;
    overflow-x: hidden;
    overflow-y: auto;

    &-item {
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      min-height: 318px;
      text-align: center;
      border: 1px #ececec solid;

      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;

      margin-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;

      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;

      &.active, &:hover {
        border: 1px #55b24b solid;

        .product-brand-item-img-wrapper {
          img {
            filter: none;
          }
        }

        .product-brand-item-hover {
          display: block;

          a {
            display: block;
            width: 100%;

            &.btn.btn-default {
              min-height: 58px;
            }
          }
        }

        .product-brand-item-none-hover {
          display: none;
        }

        p {
          color: #383838;
        }
      }

      &-img-wrapper {
        min-height: 188px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      h3 {
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        color: #666;
        min-height: 50px;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
      }

      p {
        font-size: 12px;
        line-height: 18px;
        color: #d0d0d0;
      }

      &-none-hover {
        display: block;
      }

      &-hover {
        display: none;
      }
    }
  }
}

.product-brand-none-image {
  min-height: 166px;
  width: 100%;
}

.product-brand-item-img-wrapper {
  img {
    max-width: 132px;
    max-height: 132px;
  }
}

.brand-page-title {
  font-size: 16px;
  font-weight: bold;
  color: #666;
}

.accordion .card.active:first-child .card-header .btn.btn-link.active {
  color: #55b24b;
}

.accordion .card .collapse.show .card-body ul.accordion-sub-menu li a.active {
  color: #55b24b;
}

.swiper-slide.brand-slider-slide {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 3px solid #fff;

  &.active {
    border: 3px #54ae4b solid;
    border-radius: 16px;
  }

  .product-brand-item {
    margin-bottom: 24px;

    .product-brand-item-none-hover {
      margin-bottom: 40px;

      h3 {
        margin: 0;
      }
    }

    .product-brand-item-hover {
      a {
        width: 40%;
        &.btn.btn-success {
          color: #fff;
        }
      }
    }

    .product-brand-item-img-wrapper {
      .global-grid-item-image-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 120px;

        .global-grid-item-image {
          margin: 0 auto;
          width: auto;
          height: auto;
          object-fit: scale-down;
          max-width: 100px;
          max-height: 100px;
        }
      }
    }
  }
}

.clear-brand-filter {
  color: #000;

  &:hover {
    color: #666;
  }
}

/* Arrow */
.accordion-button-item {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23616161'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;

  &.active {
    //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg);
  }
}