.userInfoModalContent{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 16px;
    padding: 16px;

    span {
        cursor: pointer;
        color: #424242;
        font-size: 16px;
        font-weight: 600;
    }
}

.infoModalMenuItems {
    display: flex;
    grid-column: span 12;
    flex-direction: column;
    list-style-type: none;
    text-align: center;
    gap: 12px;
    
    a {
        color: #424242;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
    }

    a:hover {
        color: #4CAF50;
    }

    span:hover {
        color: #4CAF50;
    }
}

.infoModalScoreSection {
    display: none;
    flex-direction: column;
    grid-column: span 12;
    justify-content: center;
    width: 100%;
    padding: 0 48px;
    text-align: center;

    p {
        font-size: 64px;
        font-weight: 500;
        color: #4CAF50;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        margin-top: -24px;

        span {
            font-size: 22px;
            font-weight: 500;
            color: #424242;
            margin-left: 8px;
        }
    }
}

.latestScoreInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;

    p {
        font-size: 16px;
        font-weight: 600;
        color: #616161;
        margin: 0;

        span {
            cursor: default;
            font-size: 16px;
            font-weight: 500;
            color: #1976D2;
            margin-right: 12px ;
        }
    }

    div {
        display: flex;
        gap: 86px;
        justify-content: space-between;
    }
}

.changedScore {
    background-color: #4CAF50;
    color: white;
    font-size: 14px;
    border-radius: 50px;
    font-weight: 500;
    padding: 8px 16px;
}

.role {
    font-size: 13px;
    text-decoration: none;
    margin-top: 10px;

    &:hover {
        text-decoration: none;    
    }
}

@media screen and (min-width: 768px) { /* Desktop */
    .infoModalMenuItems {
        grid-column: span 4; 
        border-right: 1px solid #E0E0E0;
        text-align: left;
        justify-content: flex-start;
        padding-right: 64px;
    }

    .infoModalScoreSection {
        grid-column: span 8;
        display: flex;
    }
}

