.leaderboard-section {
  padding: 2rem;
}

.leaderboard-arrangement {
  padding: 2rem;
 
}

.leaderboard-header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.leaderboard-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  padding-bottom: 24px;
}

.leaderboard-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.leaderboard-winners {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
}

.winner-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 0.3rem;
  padding: 12px;
  border-radius: 10px;
  background: linear-gradient(45deg, #e3e3e3, #f5f5f5);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
  transition: transform 0.3s ease-in-out;
  max-width: 280px;
  width: 33.333%;
  color: #fff;

  p {
    margin: 0;
    padding: 0;
  }
}

.winner-container:hover {
  transform: scale(1.05);
}

.winner-1 {
  background: linear-gradient(135deg, #f3eeea, #f3eeea);
  position: relative;
  top: -24px;
}

.winner-1::before {
  content: '1';
  position: absolute;
  top: auto;
  left: auto;
  right: 45%;
  bottom: -16px;
  padding: 8px 15px;
  background: #FFD700;
  color: white;
  border-radius: 50%;
  font-size: 0.8em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.winner-2 {
  background: linear-gradient(135deg, #f3eeea, #f3eeea);
  position: relative;
}

.winner-2::before {
  content: '2';
  position: absolute;
  top: auto;
  left: auto;
  right: 45%;
  bottom: -16px;
  padding: 8px 15px;
  background: #C0C0C0;
  color: white;
  border-radius: 50%;
  font-size: 0.8em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.winner-3 {
  background: linear-gradient(135deg, #f3eeea, #f3eeea);
  position: relative;
}

.winner-3::before {
  content: '3';
  position: absolute;
  top: auto;
  left: auto;
  right: 45%;
  bottom: -16px;
  padding: 8px 15px;
  background: #CD7F32;
  color: white;
  border-radius: 50%;
  font-size: 0.8em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.winner-rank {
  font-size: 28px;
  font-weight: bold;
}

.winner-name {
  font-size: 20px;
}

.winner-points {
  padding-bottom: 20px !important;
}

.leaderboard-others {
  margin-top: 2rem;
}

.leaderboard-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.leaderboard-table tbody {
  width: 100%;
}

.leaderboard-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.leaderboard-table tr:nth-child(odd) {
  background-color: #fff;
}

.leaderboard-table tr {
  border-bottom: 1px solid #ddd;
}

.leaderboard-table tr:hover {
  background-color: #f1f1f1;
}

.leaderboard-table td {
  padding: 0.5rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
    padding: 0;
    color: #4caf50;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
