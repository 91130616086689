.shop-page {

  &-title {
    font-size: 16px;
    color: #383838;
    margin: 0;
  }

  .global-grid-component.product-component {
    .global-grid-item {
      margin-bottom: 30px;

      a {
        
      }
    }
  }

  .filter-by-dropdown {
    .filter-by-dropdown-select.custom-select {
      height: 50px !important;
      border-radius: 20px;
      border-color: #d0d0d0;
    }
  }
}

.minus {
  background: url("../../assets/images/minus.png") no-repeat;
  width: 10px;
  height: 2px;
  display: inline-block;
}

.plus {
  background: url("../../assets/images/plus.png") no-repeat;
  width: 10px;
  height: 10px;
  display: inline-block;
}


// Shop Slider
.shop-slider {
  text-align: center;

  &-content {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    text-align: left;

    h3 {
      color: #e75b44;
      font-size: 22px;
      font-weight: bold;
      margin: 0 30px 0 0;
      padding: 0;
    }

    p {
      font-size: 12px;
      font-weight: normal;
      margin: 0;
      padding: 0;
    }
  }

  @media screen and (max-width: 991px) {

    .swiper-button-prev,
    .swiper-button-next {
      display: none !important;
    }

    .swiper-slide {
      margin-bottom: 30px;
    }
  }
}

.d-lg-flex {
  .accordion {
    flex: 1;
  }
}

.academy-component {

  .global-grid-item-image-wrapper,
  .global-grid-none-image {
    height: 175px !important;
  }

  .acamdemy-description {
    font-size: 12px;
    margin: 0 0 20px 0 !important;
  }

  .academy-time {
    font-size: 12px;
    font-weight: bold;
  }
}

.category-filter-none-item {
  width: 100%;
  text-align: center;
  color: #999;
}

.tvMagazineModal {
  padding: 2rem;

  .modal-dialog.modal-lg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .modal-content {
      background-color: #fff;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      padding: 2rem;

      .modal-header {
        border-bottom: 0;
        padding: 0 0 20px 0;
      }

      .modal-body {
        padding: 0;
      }
    }
  }
}

// Horizontal Scroll Disable
.modal-open .modal {
  overflow-y: hidden !important;
}

.accordion {
  > .card.lh-50 {
    line-height: 50px;
  }
}

.accordion .card .collapse.show .card-body ul.accordion-sub-menu li {
  line-height: 1;
}