.search-page {

  &-title {
    font-size: 16px;
    color: #383838;
    margin: 0 0 1rem 0;
  }

  .global-grid-component.product-component {
    .global-grid-item {
      margin-bottom: 30px;
    }
  }

  .filter-by-dropdown {
    .filter-by-dropdown-select.custom-select {
      height: 50px !important;
      border-radius: 20px;
      border-color: #d0d0d0;
    }
  }
}