.modalOverlay {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content {
    margin-bottom: 20px;
    color: yellow;
  }
  
  .modalCloseButton {
    background-color: #ccc;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modalCloseButton:hover {
    background-color: #bbb;
  }

  .closeIconButton {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
  }
  