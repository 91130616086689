input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 500px;
}

.swiper.sliderProductThumbnails {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.swiper-product-slider {
  height: 80%;
  width: 100%;
  border: 1px #ccc solid;
  min-height: 494px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swiper-product-thumbs-slider {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.swiper-product-thumbs-slider .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.swiper-product-thumbs-slider .swiper-slide-thumb-active {
  opacity: 1;
}

.text-yellow {
  color: #debc27 !important;
}

.text-gray-special {
  color: #424242;
}

.product-rate-comments {
  display: flex;
  margin-bottom: 1rem;

  .wrapper-rate-comments-img {
    width: 100px;
    height: 100px;
    min-width: 100px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px #ddd solid;

    img {
      width: 100px;
      height: 100px;
    }
  }

  div {
    margin-left: 0.75rem;

    h6 {
      margin: 0 0 10px 0;
    }

    p {
      margin: 0 0 10px 0;
    }
  }
}