.brand-detail-page{
  margin-top: 50px;

  .product-brand {
    height: auto !important;
    overflow: auto !important;
  }

  .brand-detail-logo {
    max-width: 120px;
  }

  .brand-detail-content {
    h2 {
      font-size: 18px;
      line-height: 24px;
      color: #666666;
      margin-bottom: 28px;
      font-weight: bold;
    }

    p {
      font-size: 14px;
      margin: 0;
      color: #666666;

      @media screen and (max-width: 991px) {
        font-size: 12px;
      }
    }

    img {
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
    }
  }

  .brand-detail-content-photo, .brand-detail-content-video {
    color: #b1b1b1;
    font-size: 12px;
    line-height: 18px;

    span {
      margin-right: 3px;
    }
  }
}

.similar-brands {
  color: #383838;
  font-size: 16px;
  margin-bottom: 40px;
}

.brands-page .product-brand-item p {
  height: 58px;
  overflow: hidden;
}

.product-brand-none-image {
  min-height: 166px;
  width: 100%;
}

.brands-page .product-brand-item p {
  margin-bottom: 0;
}

.product-brand-item-img-wrapper {
  margin-bottom: 20px;
}

.product-brand {
  .product-brand-item {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .product-brand-item-hover {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.brand-detail-image-area {
  min-height: 565px;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 50px;
}