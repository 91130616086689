html, body {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	display: flex;
	flex-direction: column;
}

.ReactModal__Overlay {
	z-index: 999 !important;
	background-color: rgba(0, 0, 0, 0.3) !important;
}

.ReactModal__Content {
	overflow: initial !important;
}

.evaluation {
	padding: 20px;
	max-width: 500px;
	width: 100%;

	@media screen and (max-width: 767px) {
		font-size: 14px;
		min-width: 300px;
	}

	@media screen and (min-width: 768px) {
		padding: 40px;
		min-width: 500px;
	}

	&-close-button {
		position: absolute;
		top: -30px;
		right: -30px;
		color: #fff;
		font-size: 30px;
		z-index: 99999;

		&:hover {
			color: #fff;
		}
	}

	&-content {
		h3 {
			font-size: 14px;
			color: #666;
			margin-bottom: 16px;
		}


		&-rate {
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-end;
			margin-bottom: 20px;

			@media screen and (min-width: 768px) {
				margin-bottom: 50px;
			}

			svg {
				color: #d0d0d0;
				font-size: 30px;
			}

			svg:hover,
			svg:hover ~ svg {
				color: #debc27;
			}
		}

		&-comment {
			display: flex;
			flex-direction: column;
			width: 100%;

			.form-control {
				resize: none;
				height: 160px;
				margin-bottom: 20px;
				font-size: 14px;
			}

			.btn.btn-success {
				color: #fff;

				@media screen and (max-width: 767px) {
					font-size: 14px;
				}
			}
		}

	}
}
