.re-send-code {
  text-decoration: none;
  color: #54ae4b;
  font-size: 14px;
  margin-top: 3rem;
  display: block;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: #3c7c35;
  }
}

.form-change-password {
  .form-group {
    margin-bottom: 40px;
  }
}

.form-btn {
  margin-top: 45px;
}

form {
  .form-body {
    label {
      padding-left: 13px;
    }

    input {
      padding-left: 23px;
    }

    label,
    input {
      font-size: 14px;
    }
  }
}

.errorMessage {
  color: #dc3545;
  padding-left: 4px;
  position: relative;
  z-index: 2;
  font-size: 14px;
}

.forgot-password {
  .form-control {
    margin-bottom: 0;
  }

  .wrapper-captcha {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
      margin: 20px 0;
      padding: 0;
      width: 100%;
    }

    .reload-captcha {
      width: 28px;
      height: 28px;
      margin: 0 0 0 10px;
      padding: 0;
      cursor: pointer;
    }
  }
}
