.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.centered-modal .modal-content {
  margin: auto;
}

.productLink {
  margin-right: 8px;
}

.productImage {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.productImageBig {
  width: 75px;
  height: 75px;
  object-fit: cover;
}

.remainingImages {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  color: #424040;
  font-size: 1.5rem;
  font-weight: 540;
  padding-left: 20px;
  padding-right: 20px;
}

.accordionContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
}

.accordionOpen {
  max-height: 500px;
  padding: 16px;
  background-color: #fff;
  border-top: 1px solid #dee2e6;
  margin-top: 24px;
}

.accordionImage {
  width: 25px;
  height: 25px;
  object-fit: cover;
}
