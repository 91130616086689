.shop-page {

  &-title {
    font-size: 16px;
    color: #383838;
    margin: 0;
  }

  .global-grid-component.product-component {
    .global-grid-item {
      margin-bottom: 30px;
    }
  }

  .filter-by-dropdown {
    .filter-by-dropdown-select.custom-select {
      height: 50px !important;
      border-radius: 20px;
      border-color: #d0d0d0;
    }
  }
}

.minus {
  background: url("../../assets/images/minus.png") no-repeat;
  width: 10px;
  height: 2px;
  display: inline-block;
}

.plus {
  background: url("../../assets/images/plus.png") no-repeat;
  width: 10px;
  height: 10px;
  display: inline-block;
}


// Shop Slider
.shop-slider {
  text-align: center;

  &-content {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    text-align: left;

    h3 {
      color: #e75b44;
      font-size: 22px;
      font-weight: bold;
      margin: 0 30px 0 0;
      padding: 0;
    }

    p {
      font-size: 12px;
      font-weight: normal;
      margin: 0;
      padding: 0;
    }
  }

  @media screen and (max-width: 991px) {

    .swiper-button-prev,
    .swiper-button-next {
      display: none !important;
    }

    .swiper-slide {
      margin-bottom: 30px;
    }
  }
}

.d-lg-flex {
  .accordion {
    flex: 1;
  }
}

.academy-component {

  .global-grid-item-image-wrapper,
  .global-grid-none-image {
    height: 175px !important;
  }

  .acamdemy-description {
    font-size: 12px;
    margin: 0 0 20px 0 !important;
  }

  .academy-time {
    font-size: 12px;
    font-weight: bold;
  }
}

.category-filter-none-item {
  width: 100%;
  text-align: center;
  color: #999;
}

.magazine-modal {
  &-close-button {
    position: absolute;
    top: -40px;
    right: -40px;
    color: #fff;
    font-size: 30px;
    cursor: pointer;

    &:hover {
      color: #ccc;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &-image {
      overflow: hidden;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
    }

    &-category {
      font-size: 14px;
      margin-bottom: 10px;
    }

    &-date {
      font-size: 12px;
      font-weight: bold;
    }
  }

  .modal-dialog.modal-lg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .modal-content {
      background-color: #fff;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      padding: 0;

      .modal-header {
        border-bottom: 0;
        padding-bottom: 0;
      }

      .modal-body {
        padding: 2rem;
      }
    }
  }

  .magazine-modal-description {
    color: #666;

    h3 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
      color: #666;
    }

    .magazine-modal-description-area {
      height: 500px;
      overflow: auto;

      p {
        font-size: 12px;
        color: #666;
      }
    }
  }
}

.magazine-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 54px !important;
}

// Horizontal Scroll Disable
.modal-open .modal {
  overflow-y: hidden !important;
}

.accordion {
  > .card.lh-50 {
    line-height: 50px;
  }
}

.accordion .card .collapse.show .card-body ul.accordion-sub-menu li {
  line-height: 1;
}