.toast {

  p {
    margin: 0;
  }

  &Buttons {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    padding-top: 4px;
  }

  &ButtonSuccess {
    background-color: #4CAF50;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
  }

  &ButtonCancel {
    background-color: #f44336;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
  }
}