.form-change-password {
  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    background-image: none !important;
  }

  .password-eye,
  .password-eye-slash {
    position: absolute;
    top: 60px;
    right: 10px;
    transform: translateY(-50%);
  }
}
