@import "theme.scss";
// @import "../node_modules/bootstrap/scss/bootstrap.scss";

* {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lexend', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: 16px;
}

footer {
  margin-top: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.flex {
  display: flex;
}

.center-center {
  justify-content: center;
  align-items: center;
}

.p-10 {
  padding: 10px;
}

.m-10 {
  margin: 10px;
}

a:hover {
  text-decoration: none !important;
}

.main-container {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.global-grid-component.product-component .global-grid-item {
  min-height: 407px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 1199px) {
    min-height: 431px !important;
  }
}


.global-grid-component.product-component .home-brand .global-grid-item {
  min-height: 210px !important;
  justify-content: center;

  @media screen and (max-width: 1199px) {
    min-height: 210px !important;
  }
}

.height-48 {
  height: 48px;
}

.height-80 {
  height: 80px;
}

input.counter {
  width: 36px;
  height: 28px;
  border: 1px #d0d0d0 solid;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}

.btn-cancel {
  color: #fff;
  background-color: #C62828;
  border-color: #C62828;
  font-size: 14px;
  line-height: 21px;
  min-width: 120px;
  margin-top: 0.75rem;

  &:hover {
    color: #fff;
    background-color: #a82222;
    border-color: #9e2020;
  }
}

.navbar-logo {
  text-decoration: none !important;
  text-align: center;
}

.d-lg-flex.d-none.text-white.small.mb-4.opacity-50, .d-lg-flex.d-none.text-white.small.mb-5.opacity-50 {
  opacity: 1 !important;
}

.leaderboard-winners {
  .winner-container {
    .winner-name, .winner-points {
      color: #000;
    }
  }
}

.wrapper-performance-banner {
  position: relative;

  .img-performance-banner {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .item-performance-banner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -60px;
    margin-left: 56px;

    @media screen and (max-width: 1399px) {
      margin-top: -56px;
      margin-left: 44px;
    }

    @media screen and (max-width: 1199px) {
      margin-top: -45px;
      margin-left: 24px;
    }

    @media screen and (max-width: 1024px) {
      margin-top: -40px;
      margin-left: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: -30px;
      margin-left: 24px;
    }

    @media screen and (max-width: 414px) {
      margin-top: -23px;
      margin-left: 24px;
    }

    @media screen and (max-width: 320px) {
      margin-top: -20px;
      margin-left: 15px;
    }

    span {
      font-size: 36px;
      display: block;

      @media screen and (max-width: 1199px) {
        font-size: 30px;
      }

      @media screen and (max-width: 1024px) {
        font-size: 24px;
      }

      @media screen and (max-width: 767px) {
        font-size: 20px;
      }

      @media screen and (max-width: 414px) {
        font-size: 14px;
      }

      @media screen and (max-width: 320px) {
        font-size: 12px;
      }
    }
  }
}

.form-select.text-white {
  background-color: #343a40; /* Koyu bir arka plan rengi */
  color: white;
}

.form-select.text-white option {
  background-color: #343a40; /* Dropdown içindeki seçeneklerin arka plan rengi */
  color: white; /* Seçenek metin rengi */
}

.form-select.text-white option:checked {
  background-color: #343a40; /* Seçenekler açıldığında arka plan koyu olur */
  color: white; /* Seçilen opsiyonun metni beyaz olur */
}

.form-select.text-white option:hover {
  background-color: #007bff; /* Mavi arka plan */
  color: white; /* Hover sırasında beyaz yazı */
}