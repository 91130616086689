.home-survey {
  &-question {
    font-size: 14px;
    line-height: 21px;
    color: #666;
    text-align: center;
    margin: 0 0 50px 0;
    padding: 0;
  }

  .home-survey-form {
    .btn {
      max-width: 400px;
      width: 100%;
      display: block;
      margin: 50px auto 0px auto;
    }

    .home-survey-list {
      list-style: none;
      margin: 30px 0 0 0;
      padding: 0;
      display: flex;
      flex-direction: row;

      li {
        list-style: none;
        margin: 0;
        padding: 0;
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        label {
          font-size: 14px;
          line-height: 32px;
          font-weight: bold;
          margin-top: 1rem;
          color: #666;
        }
      }

    }
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
      position: relative;
      padding-left: 50px;
      cursor: pointer;
      line-height: 32px;
      display: inline-block;
      color: #fff;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 32px;
      height: 32px;
      border: 2px solid #ccc;
      border-radius: 100%;
      background: transparent;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 20px;
      height: 20px;
      background: #ccc;
      position: absolute;
      top: 6px;
      left: 6px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
  }
}

.home-survey-result {
  display: block;
  width: 100%;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    width: 100%;

    li {
      list-style: none;
      margin: 0 0 0 16px;
      padding: 0;
      height: 60px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        color: #fff;
        font-weight: bold;
        font-size: 13px;
      }

      //&:after {
      //  content: '';
      //  width: 0;
      //  height: 0;
      //  border-style: solid;
      //  border-width: 60px 60px 0 0;
      //  border-color: #53bce5 transparent transparent transparent;
      //  position: absolute;
      //  top: 0;
      //  right: -60px;
      //}

      &:nth-child(1) {
        //width: calc( 45% - 60px );
        width: 45%;
        background-color: #53bce5;

        //&:after {
        //  content: '';
        //  border-color: #53bce5 transparent transparent transparent;
        //}
      }

      &:nth-child(2) {
        width: 30%;
        background-color: #debc27;

        //&:after {
        //  content: '';
        //  border-color: #debc27 transparent transparent transparent;
        //}
      }

      &:nth-child(3) {
        width: 15%;
        background-color: #d77e29;

        //&:after {
        //  content: '';
        //  border-color: #d77e29 transparent transparent transparent;
        //}
      }

      &:nth-child(4) {
        width: 5%;
        background-color: #99978d;

        //&:after {
        //  content: '';
        //  border-color: #99978d transparent transparent transparent;
        //}
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .home-survey-result-items {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;

    > div.row {
      flex: 1;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
    }

    div.col {
      text-align: center;
      font-size: 14px;
      color: #666;
      font-weight: bold;

      span {
        display: inline-block;
        vertical-align: middle;

        &:first-child {
          width: 40px;
          height: 20px;
          margin-right: 10px;

          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          border-radius: 20px;
        }
      }

      &:first-child {
        span {
          &:first-child {
            background-color: #53bce5;
          }
        }
      }

      &:nth-child(2) {
        span {
          &:first-child {
            background-color: #debc27;
          }
        }
      }

      &:nth-child(3) {
        span {
          &:first-child {
            background-color: #d77e29;
          }
        }
      }

      &:nth-child(4) {
        span {
          &:first-child {
            background-color: #99978d;
          }
        }
      }

    }

  }
}

.home-survey-result-text {
  font-size: 12px;
  line-height: 21px;
  color: #666;
}
