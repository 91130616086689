.profile-page {

  .profile-user-info {
    > div {

    }
  }

  .profile-user {
    margin-bottom: 2rem;

    &-short-name {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      border: 5px #d0d0d0 solid;
      color: #666666;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      width: 100px;
      height: 100px;
      font-size: 36px;
    }

    &-name {
      color: #666666;
      font-weight: bold;
      margin-top: 30px;
      @media screen and (min-width: 768px) {
      }
    }
  }

  .profile-header-content {
    @media screen and (max-width: 991px) {
      margin-bottom: 40px;
    }

    p {
      color: #383838;
      font-size: 12px;
      line-height: 21px;
      margin-bottom: 0;
    }
  }

  .profile-body {
    .nav.nav-tabs {
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      border: 2px #d0d0d0 solid;
      overflow: hidden;

      > a.nav-item.nav-link {
        border: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        padding: 15px 0 14px 0;
        background-color: #fff;
        color: #d0d0d0;
        font-size: 14px;

        &.active {
          background-color: #54ae4b;
          color: #fff;
        }
      }
    }

    .tab-content {
      @media screen and (min-width: 320px) {
        margin-top: 30px;
      }

      @media screen and (min-width: 768px) {
        margin-top: 60px;
      }

      @media screen and (min-width: 992px) {
        margin-top: 90px;
      }
    }

    form {
      .form-row {
        .col {
          &:first-child {
            margin-right: 15px;
          }
        }

        .form-group {
          margin-bottom: 45px;

          input, textarea, select {
            border: 2px #d0d0d0 solid;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
          }

          textarea {
            resize: none;
            padding: 20px;
            position: relative;
          }
        }
      }
    }
  }

  .message-box {
    position: relative;

    &-glob {
      margin-bottom: 0;

      input {
        border: none;
        padding-right: 150px;
      }
    }

    &-actions {
      position: absolute;
      right: 28px;
      top: 136px;
      z-index: 999;

      &-glob {
        top: 50%;
        margin: -15px 0 0 0;
      }

      button {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        margin: 0 0 0 10px;
        padding: 0;
        width: 22px !important;
        height: 22px !important;
        line-height: 22px !important;
        outline: 0;
        box-shadow: none;
        font-size: 14px;
        text-align: center;
        background-color: #777;
        color: #c1c1c1;
        border: none;

        &.active {
          width: 30px !important;
          height: 30px !important;
          background-color: #54ae4b;
          color: #a1cb98;
        }
      }
    }
  }
}

.message-box-content.accordion {
  //border: 1px #54ae4b solid;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  overflow: hidden;
  padding: 0;
  margin: 0;

  .accordion-status-passive {
    border: 1px #54ae4b solid;
    padding: 23px 26px 23px 33px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }

  .accordion-status-active {
    border: 1px #54ae4b solid;
    padding: 23px 26px 23px 33px;
    -webkit-border-top-left-radius: 20px;
    -moz-border-top-left-radius: 20px;
    border-top-left-radius: 20px;

    -webkit-border-top-right-radius: 20px;
    -moz-border-top-right-radius: 20px;
    border-top-right-radius: 20px;
  }


  .accordion-status-passive, .accordion-status-active {
    .message-status {
      color: #bc450f;
      font-weight: bold;

      > span.message-status-icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        background-color: #bc450f;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        vertical-align: middle;
        margin-left: 20px;
      }

      &.active {
        color: #54ae4b;

        > span.message-status-icon {
          background-color: #54ae4b;
        }
      }
    }

    .message-history {
      color: #464646;
      font-size: 14px;

      &-triangle {
        background: url("../../assets/images/triangle-bottom.png");
        width: 11px;
        height: 8px;
        display: inline-block;
        margin-left: 27px;

        &.active {
          background: url("../../assets/images/triangle-top.png");
        }
      }
    }
  }

  > div.card {
    background: #fff;
    margin-bottom: 45px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    padding: 0;

    &.old-month {
      background-color: #efefef;

      .card-header {
        margin-bottom: 0 !important;
      }

      .accordion-status-passive, .accordion-status-active {
        border-color: #efefef;
      }

      .collapse.show {
        border: 1px #efefef solid;
      }
    }

    &-active {
      border-color: #54ae4b;
    }

    > div.card-header {
      padding: 0;
    }

    h3 {
      font-weight: bold;
      font-size: 18px;
      color: #505050;
      margin-bottom: 20px;
    }

    p {
      color: #464646;
      margin: 0;
    }

  }

  .collapse.show {
    padding: 0 !important;
    border: 1px #54ae4b solid;
    -webkit-border-bottom-left-radius: 20px;
    -moz-border-bottom-left-radius: 20px;
    border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    -moz-border-bottom-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top: none;
    overflow: hidden;

    > .card-body {
      margin: 0;
      padding: 0;
    }
  }
}

.conversation {
  display: flex;
  flex-direction: column;
  width: 100%;

  &-dark-blue, &-grey, &-blue {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    padding: 0 22px;

    &:first-child {
      padding: 30px 22px 0 22px;
    }

    > .conversation-date {
      font-size: 10px;
      color: #cfcfcf;
      margin-bottom: 10px;
      margin-right: 20px;
    }

    > .conversation-content {
      color: #fff;
      padding: 24px;
      width: 100%;
      max-width: 70%;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      margin-bottom: 35px;
    }
  }

  &-dark-blue {
    > .conversation-content {
      background-color: #2b3990; /* For browsers that do not support gradients */
      background-image: linear-gradient(#2b3990, #2a4d9f);
    }
  }

  &-grey {
    align-items: flex-start;
    text-align: left;

    > .conversation-content {
      max-width: 60%;
      background-color: #666666; /* For browsers that do not support gradients */
      background-image: none;
    }
  }

  &-blue {
    > .conversation-content {
      background-color: #297fc2; /* For browsers that do not support gradients */
      background-image: linear-gradient(#297fc2, #27a1db);
    }
  }

  .conversation-send-message {
    border-top: 1px #54ae4b solid;

    input {
      border: none;
      padding: 29px 22px 28px 22px;
      -webkit-border-bottom-left-radius: 20px;
      -moz-border-bottom-left-radius: 20px;
      border-bottom-left-radius: 20px;
      -webkit-border-bottom-right-radius: 20px;
      -moz-border-bottom-right-radius: 20px;
      border-bottom-right-radius: 20px;
      height: auto;
    }
  }
}

.accordion > .card > .card-header {
  margin-bottom: 0 !important;
  padding: 0;
}

// Address Page
.address-page {

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 100px;

    @media screen and (max-width: 991px) {
      margin-bottom: 30px;
    }

    > a {
      font-size: 14px;
      color: #666666;

      &:hover {
        color: #999;
      }

      > span {
        margin-left: 5px;
        display: inline-block;
      }
    }

    h3 {
      font-size: 16px;
      color: #383838;
    }
  }

  .address {
    background-color: #efefef;
    padding: 23px 33px;
    margin-bottom: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    border: 1px #efefef solid;

    &.active {
      background-color: #fff;
      border: 1px #54ae4b solid;

      .address-status {
        background-color: #54ae4b;
      }
    }

    &-content {
      h3.address-title {
        color: #505050;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
        padding: 0;
      }

      div {
        p {
          font-size: 14px;
          color: #464646;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &-actions {
      display: flex;
      flex-direction: row;

      > a {
        margin-left: 40px;
      }

      .address-edit {
        color: #666666;
      }

      .address-remove {
        color: #bc450f;
      }

      .address-status {
        width: 19px;
        height: 19px;
        background-color: #bc450f;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        margin-left: 40px;
        cursor: pointer;
      }
    }
  }
}

.wrapper-image-container {
  h3 {
    font-size: 14px;
    color: #666;
    margin-bottom: 30px;
    min-height: 27px;

    @media screen and (max-width: 991px) {
      font-size: 12px;
      margin-bottom: 14px;
    }
  }

  @media screen and (max-width: 991px) {
    > .row {
      > .col-6 {
        &:nth-child(odd) {
          padding-right: 6px;
        }

        &:nth-child(even) {
          padding-left: 6px;
        }

        button.upload-image-button {
          div {
            font-size: 12px;
            color: #d0d0d0;
          }
        }
      }
    }
  }

  .image-container {
    border: 2px #d0d0d0 solid;
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;

    > img {
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
    }

    div.position-relative {
      img.img-fluid {
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
      }

      a {
        position: absolute;
        top: 10px;
        right: 10px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        background: #f9f3ee;

        svg {
          margin-top: 4px;
          margin-left: 4px;
          width: 18px;
          height: 18px;
          color: #bc450f;
        }
      }
    }

    .upload__image-wrapper {
      button.upload-image-button {
        border: none;
        background-color: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 220px;
        justify-content: center;
        align-items: center;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;

        @media screen and (max-width: 991px) {
          height: 102px;
          margin-bottom: 14px;
        }

        > img {
          margin-bottom: 10px;
        }

        div {
          color: #d0d0d0;
        }
      }

      button.remove-image-button {
        border: none;
        background: #f8f3ee;
        width: 26px;
        height: 26px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        right: 5px;
        overflow: hidden;

        > svg {
          color: #bc450f;
        }
      }
    }

    .uploaded-image-content {
      position: relative;

      img {
        width: 100%;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
      }
    }
  }
}

//order-item
.order-item {

  .order-image {
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background: #fff;
      max-width: 75px;
      width: 100%;
      margin-left: 20px;
      margin-right: 35px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > img {
        max-width: 75px;
      }
    }
  }

  .order-process {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    &-graph {
      background-color: #f2f2f2;
      position: relative;
      width: 100%;
      height: 16px;
      border: 1px #f2f2f2 solid;
      border-radius: 20px;

      &-percent {
        border-radius: 20px;
        background-color: #54ae4b;
        width: 25%;
        height: 14px;
        top: 1px;
        left: 0;
      }
    }

    &-status {
      font-size: 10px;
      color: #474747;
      margin-top: 8px;
    }
  }

  .order-point {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    font-weight: bold;
    font-size: 14px;
  }


  .order-detail {
    padding: 28px 46px 38px 46px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;

      li {
        list-style: none;
        margin: 0 0 10px 0;
        padding: 0;

        &:last-child {
          .wrapper-order-cargo-status-style {
            display: none;
          }
        }

        > div.order-cargo-status-content {
          display: flex;
          flex-direction: row;
          width: 100%;
          margin: 0 0 10px 0;

          div.wrapper-check {
            width: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;

            div.rounded-circle {
              width: 19px;
              height: 19px;
              background-color: #ededed;
            }
          }

          div {
            h4 {
              font-size: 14px;
              color: #d8d8d8;
              font-weight: bold;
              margin-bottom: 0;
            }

            p {
              display: none;
            }
          }
        }

        .wrapper-order-cargo-status-style {
          width: 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .order-cargo-status-style {
            width: 9px;
            height: 60px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
            background-color: #ededed;
          }
        }

        &.order-cargo-status-success {
          > div.order-cargo-status-content {
            div.rounded-circle {
              display: none;
            }

            div.wrapper-check {
              width: 30px;
              display: flex;
              flex-direction: column;
              align-items: center;

              div.check {
                display: inline-block;
                background: url("../../assets/images/check.png") no-repeat;
                width: 25px;
                height: 25px;
              }
            }

            div {
              h4 {
                color: #54ae4b;
              }

              p {
                display: block;
                font-size: 11px;
                color: #474747;
              }
            }
          }
        }

      }
    }
  }
}


// Datatable
.rdt_TableHead {

}

.rdt_TableBody {
  .rdt_TableRow {
    &:nth-child(odd) {
      color: #666;
    }

    &:nth-child(even) {
      color: #ccc;
    }

    &:not(:last-of-type) {
      border: none;
    }
  }
}


// Mobile

.detail-page-mobile-categories {
  h3 {
    margin-top: 46px;
    color: #474747;
    font-size: 12px;
    font-weight: bold;

    @media screen and (max-width: 991px) {
      margin-top: 0;
    }
  }

  .form-control {
    border: 2px #d0d0d0 solid;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    height: 50px;
    line-height: 50px;
    margin-bottom: 40px;
    margin-top: 14px;
    color: #d0d0d0;
  }
}


.wrapper-point {
  margin-bottom: 20px;

  svg {
    color: #bfbfbf;
    font-size: 40px;
  }

  .point-number {
    font-size: 20px;
    font-weight: bold;
    color: #666;
    margin-left: 10px;
    margin-right: 5px;
    line-height: 15px;
    vertical-align: bottom;
  }

  .point-text {
    font-size: 20px;
    font-weight: bold;
    color: #666;
    line-height: 15px;
    vertical-align: bottom;
  }
}


.rdt_Table {
  .sc-egiyK {
    font-size: 14px;
  }

  .sc-hKwDye {
    font-size: 14px;
  }
}

.point-history {
  .nav-item.nav-link {
    width: 33.333%;
  }
}

.opacity-03 {
  opacity: 0.3;
}

.profile-user {
  .wrapper-profile-photo {
    width: 100px;
    margin-bottom: 2rem;
  }
}

.password-change {
  .password-eye, .password-eye-slash {
    top: 25px;
  }

  .was-validated .form-control:invalid, .form-control.is-invalid {
    background: transparent;
  }
}