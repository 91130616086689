.games-page {
  color: #fff;

  h1, h2, h3 {
    color: #fff;
  }

  .games {
    background-image: url(../../assets/images/games/bg.jpg);
    background-size: cover;
    width: 100%;
    height: 653px;
    margin: 0 auto;
    padding: 15px 0;

    div:not(.col-md-3.col-6) {
      @media screen and (min-width: 1250px) {
        height: 100%;
      }
    }

    .row {
      align-items: flex-end;
    }
  }

  .competition-link {
    text-align: center;
    display: block;
    width: 180px;
    height: 180px;
    margin: 0 auto 40px auto;
    padding: 20px;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    border: 1px #ececec solid;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    cursor: pointer;

    &:hover {
      > span {
        color: #54ae4b;
      }
    }

    > span {
      color: #666;
      font-weight: 500;
      font-size: 14px;
      margin-top: 6px;
      display: inline-block;

      &:first-child {
        display: block;
        width: 100px;
        height: 100px;
        margin: 0 auto;
      }
    }
  }
}