.game-score-hunt {
  .score-hunt-page.wheel-page {
    background-image: radial-gradient(circle at 50% 34%, #1e2524, #0e46a5 122%);

    .game-timer {
      min-height: 100px !important;
    }
    
    .games-page-circle {
      img {
        max-width: 122px;
        width: 100%;
      }
    }

    .wrapper-wp-start {
      font-family: "Lexend", sans-serif !important;

      h2 {
        font-size: 36px;
        font-weight: 700;
      }

      p {
        font-size: 16px;
        font-weight: 300;
        margin: 0 !important;
      }

      button {
        font-weight: 600;
        font-size: 16px;
      }
    }
    
    button.btn.btn-success, button.btn.btn-info, button.btn.btn-danger, button.btn.btn-warning {
      max-width: 150px;
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      border-radius: 8px !important;
    }

    .wrapper-question-start {
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
      min-height: 100%;
      display: flex;
      flex-direction: row;

      .wrapper-question {
        padding: 30px;
        width: 100%;
      }
    }
  }

  .wrapper-answer-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 40px 0;

    .form-check {
      display: flex;
    }

    @media screen and (max-width: 991px) {
      .form-check {
        width: 50%;
        margin-bottom: 10px;
      }
    }

    @media screen and (max-width: 767px) {
      .form-check {
        width: 100%;
        justify-content: start;
        align-items: flex-start;
        padding-left: 0 !important;
      }
    }
  }
}