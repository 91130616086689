.academy-author {
  display: flex;
  flex-direction: row;

  > .academy-author-abbreviation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border: 6px #ccc solid;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-size: 24px;
    font-weight: bold;
    color: #616161;
    margin-right: 10px;
  }

  > .academy-author-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      padding: 0;
      color: #464646;
    }

    p {
      font-size: 12px;
      margin: 0;
      padding: 0;
      color: #c8c8c8;
    }
  }
}

.accordion-sub {

}
.video-modal-content {
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 18px;
    font-weight: bold;
    color: #666;
    margin-bottom: 16px;
  }

  .video-modal-answer-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin-bottom: 16px;

      a {
        display: block;
        color: #ccc;
        border: 1px #ccc solid;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
        width: 100%;
        padding: 16px;
        font-size: 14px;
      }

    }
  }
}


.question-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 9999;
}

.question-box {
  background: white;
  color: black;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.options button {
  margin: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.options button:hover {
  background-color: #0056b3;
}

.academy-detail-topic-list {
  list-style: none;
  margin: 0;
  padding: 0;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  li {
    margin: 0;
    padding: 0;
  }

  .academy-detail-subtopic-list {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    li {
      margin: 0;
      padding: 4px 0 4px 20px;

      button {
        margin: 0;
        padding: 0;
        border: 0;
        background: transparent;
        color: #111;

        &:hover, &.active {
          color: #999;
        }
      }
    }
  }
}

.videoPlayer {
  @media screen and (min-width: 1299px) {
    min-height: 500px;
  }
}