.errorMessage {
  color: #dc3545;
  padding-left: 4px;
  position: relative;
  z-index: 2;
  font-size: 14px;
}

.formCheckbox {
    margin-top: -36px;
    margin-bottom: 16px;
    padding: 0;
}