.find-mate-page {
  //background-image: radial-gradient(circle at 50% 34%, #f62a6d, #4b3190 122%);
  position: relative;
  height: 100%;

  &-title {
    color: #fff;
    font-size: 36px;
  }

  &-desc {
    color: #fff;
    font-size: 14px;
  }

  .games-page-question {
    color: #fff;

    h3 {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      margin: 0 0 4px 0;
    }

    form {
      .form-group {
        > label {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 20px;
        }
      }

      .form-check {
        label {
          font-size: 16px;
        }
      }
    }
  }

  .game-board {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    perspective: 1000px;
    margin-bottom: -15px;
    max-width: 528px;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 40px auto 0 auto;

    .card {
      width: 100%;  
      max-width: 160px;
      user-select: none;
      height: 200px;
      padding: 0;
      box-sizing: border-box;
      text-align: center;
      margin: 0 24px 24px 0;
      transition: 0.6s;
      transform-style: preserve-3d;
      position: relative;
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      border-radius: 16px;
      border: 4px transparent solid;

      &:nth-child(3n+3) {
        margin-right: 0;
      }

      div {
        backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        transition: 0.6s;
        background: #fff;
      }
      .back {
        font-size: 50px;
        line-height: 200px;
        cursor: pointer;
        // color: darken(#ee6910, 30%);
        color: #6d1124;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .front {
        transform: rotateY(180deg);
        line-height: 110px;
        text-emphasis: none;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
          vertical-align: middle;
          width: 100%;
          max-width: 160px;
        }
      }
      &.flipped {
        transform: rotateY(180deg);
      }
      &.matched {
        transform: rotateY(180deg);
        border: 4px #76e17b solid;

        .front {
          box-shadow: 0 0 0 2px rgba(#000, 0.05) inset;
          // animation: selected 0.8s 0s ease 1;
          // animation-fill-mode: both;
          // opacity: 0.2;
        }
      }
    }
  }
  
  .restart-button {
    width: 12em;
    height: 3em;
    cursor: pointer;
    color: rgb(255, 255, 255);
    border-radius: 4px;
    font-size: 12px;
    background-color: #6d1124;
    border: 0;
  }
  
  .centered {
    width: 100%;
    height: 100%;
    text-align: center;
  }
  
  @keyframes selected {
    0% {
      opacity: 0.2;
    }
    30% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.9;
    }
    70% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.3;
    }
  }
  


}
  
// Transforms: Rotates
.wheel-spin {
  transform: rotate(-45deg);
}

.wheel-page .wheel-spin-button > button.btn {
  transform: rotate(45deg);
}

// Triangle
.wheel-spin .gTLZXx {
  transform: rotate(45deg) !important;
}

.games-page-introduction {
  padding: 50px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .games-page-circle {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    width: 215px;
    height: 215px;
    background-color: #fff;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .btn.btn-success {
    padding: 16px 48px;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    border-radius: 8px !important;
    background-color: #54ae4b;
    border-color: #54ae4b;

    &:hover {
      background-color: #28a745;
      border-color: #28a745;
    }
  }

  .wrapper-reward {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: auto;
    width: 100%;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    opacity: 0;

    &.active {
      opacity: 1;
    }

    h3 {
      color: #fff;
      font-weight: bold;
      font-size: 16px;
      margin: 0 0 10px 0;
      padding: 0;
    }

    // Girls Point
    h4 {
      color: #fba629;
      font-weight: bold;
      font-size: 48px;
      margin: -40px 0 0 0;
      padding: 0;
      position: absolute;
    }
  }

}

.wheelWin {
  max-width: 0 !important;
  -webkit-transition: max-width 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: max-width 1s ease-in-out;
  
  &.active {
    max-width: 50% !important;

    @media screen and (max-width: 767px) {
      max-width: 100% !important;
      margin-top: 30px;
    }
  }
}

.questionWheelWin {
  height: 0;
  -webkit-transition: height 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: height 1s ease-in-out;

  .wrapper-reward {
    img {
      display: none;
    }
  }

  &.active {
    height: 100% !important;

    .wrapper-reward {
      img {
        display: block;
      }
    }
  }
}

.control {
  font-family: "Lexend", sans-serif !important;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 16px;
}
  .control input {
      position: absolute;
      z-index: -1;
      opacity: 0;
  }
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border: 0px solid #000000;
  border-radius: undefinedpx;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #cccccc;
}

.control input:checked ~ .control_indicator {
  background: #2aa1c0;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: red;
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-radio .control_indicator {
  border-radius: 50%;
}

.control-radio .control_indicator:after {
  left: 2px;
  top: 2px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ffffff;
  transition: background 250ms;
}
.control-radio input:disabled ~ .control_indicator:after {
  background: #7b7b7b;
}.control-radio .control_indicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: -1.3rem;
  margin-top: -1.3rem;
  background: #2aa1c0;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}
@keyframes s-ripple {
  0% {
      opacity: 0;
      transform: scale(0);
  }
  20% {
      transform: scale(1);
  }
  100% {
      opacity: 0.01;
      transform: scale(1);
  }
}
@keyframes s-ripple-dup {
  0% {
      transform: scale(0);
  }
  30% {
      transform: scale(1);
  }
  60% {
      transform: scale(1);
  }
  100% {
      opacity: 0;
      transform: scale(1);
  }
}
.control-radio input + .control_indicator::before {
  animation: s-ripple 250ms ease-out;
}
.control-radio input:checked + .control_indicator::before {
  animation-name: s-ripple-dup;
}


.mb-3.col-sm-10 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;

  .form-check {
    margin-bottom: 10px;
    padding-left: 0;
    width: 50%;
    text-align: start;
  }
}

.find-mate-page {
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
      position: relative;
      padding-left: 50px;
      cursor: pointer;
      line-height: 32px;
      display: inline-block;
      color: #fff;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 32px;
      height: 32px;
      border: 2px solid #fff;
      border-radius: 100%;
      background: transparent;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 20px;
      height: 20px;
      background: #fff;
      position: absolute;
      top: 6px;
      left: 6px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
  }
}


.wrapper-reward {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: auto;
  width: 100%;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;

  &.active {
    opacity: 1;
  }

  h3 {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    margin: 0 0 10px 0;
    padding: 0;
  }

  // Girls Point
  h4 {
    color: #fba629;
    font-weight: bold;
    font-size: 48px;
    margin: -40px 0 0 0;
    padding: 0;
    position: absolute;
  }
}

.find-mate-win {
  max-width: 0 !important;
  -webkit-transition: max-width 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: max-width 1s ease-in-out;

  h1 {
    color: #fff;
  }

  &.active {
    max-width: 100% !important;

    @media screen and (max-width: 767px) {
      max-width: 100% !important;
      margin-top: 30px;
    }
  }
}