@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;600;700&display=swap");

.game-word-puzzles {
  .word-puzzles-page {
    background-image: radial-gradient(circle at 50% 34%, #c70ced, #4b3190 122%);
  }

  .games-page-circle {
    img {
      max-width: 122px;
      width: 100%;
    }
  }

  .wrapper-wp-start {
    font-family: "Lexend", sans-serif !important;

    h2 {
      font-size: 36px;
      font-weight: 700;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      margin: 0 !important;
    }

    button {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .hangman-competition {
    font-family: "Lexend", sans-serif !important;

    button {
      font-size: 16px;
      font-weight: 600;
      color: #fff;

      &.btn-new-letters {
        background: transparent;
        max-width: 238px;
        width: 100%;
        height: 56px;
        line-height: 56px;
        margin: 0;
        padding: 0;
        border: solid 2px #fff;
        -webkit-border-radius: 8px !important;
        -moz-border-radius: 8px !important;
        border-radius: 8px !important;
        box-shadow: none;
        outline: 0;
        margin-right: 24px;

        &:hover,
        &:active,
        &:focus,
        &:visited {
          border: solid 2px #fff !important;
          background: transparent !important;
          box-shadow: none !important;
        }

        span {
          margin-left: 6px;
        }
      }
    }

    .hangman-information {
      max-width: 80%;
      margin: 0 auto;

      h2 {
        color: #fff;
        font-size: 36px;
        font-weight: bold;
        line-height: 1.33;
      }
    }

    .hangman-answer {
      max-width: 700px;

      .form-group {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        input.guessLetter {
          transition: none;
          border-top-left-radius: 8px !important;
          border-bottom-left-radius: 8px !important;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          text-transform: uppercase;
          font-size: 36px;
          font-weight: bold;
          line-height: 1.33;
          text-align: center;

          &.text-danger {
            border: solid 2px #bc2727 !important;
            color: #bc2727 !important;
          }

          &.text-success {
            border: solid 2px #54ae4b !important;
            color: #54ae4b !important;
          }

          &:focus,
          &:active {
            border: 0;
            box-shadow: none;
          }
        }

        button.btn.btn-success {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          max-width: 178px;
          width: 100%;
          padding-left: 16px;
          padding-right: 16px;
        }

        button.btn.btn-danger {
          background-color: #bc2727 !important;
          border-color: #bc2727 !important;
        }
      }
    }

    .hangman-letters {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 40px 0 40px 0;

      .hangman-letters-items {
        margin-right: 48px;

        &:last-child {
          margin-right: 0;
        }

        input {
          width: 48px;
          height: 56px;
          margin: 0 8px;
          border-radius: 8px;
          background-color: #fff;
          border: 0;
          font-family: "Lexend", sans-serif !important;
          font-size: 36px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: #000;
          text-align: center;
          outline: 0;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          &:hover,
          &:active {
            box-shadow: none;
            outline: 0;
            border: 0;
          }
        }
      }
    }

    .hangman-guess-letters {
      margin-bottom: 40px;

      h5 {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.33;
        color: #fff;
        margin: 0;
        padding: 0;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        align-items: center;

        li {
          list-style: none;
          margin-right: 6px;
          color: #fff;
          font-size: 36px;
          font-weight: bold;
          line-height: 1.33;
        }
      }
    }
  }
}

.isComplete {
  margin-top: 2rem;
}

.keyboard {
  width: calc(100%);
  padding: 0 20px 20px 20px;
  text-align: center;

  .keyboard-line {
    height: 7vh;
    min-height: 50px;
    max-width: 700px;
    margin: 4px auto;

    .key {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: calc(8% - 4px);
      border: solid 0px;
      background: #c7c7c7;
      font-size: 20px;
      user-select: none;
      height: 100%;
      vertical-align: top;
      border-radius: 5px;
      margin: 2px 2px;
      transition: background-color 1.25s, color 1.25s;

      &:disabled {
        background-color: #dc3545;

        &:hover {
          background-color: #dc3545;
        }
      }

      &.enter {
        background: #54ae4b;
        color: white;
      }

      &.delete {
        background: #9d9d9d;
        color: white;
      }

      &:hover {
        background-color: #999;
      }

      span {
        height: 100%;
        display: flex;
        align-items: center;
        margin: 0 auto;
      }
    }
  }
}

.fs-36 {
  font-size: 36px;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
