.password-eye, .password-eye-slash {
    position: absolute;
    top: 100%;
    right: 20px;
    font-size: 18px;
    margin-top: -36px;
    color: #555;
}

.auth-logo {
    // max-width: 110px;
    max-width: 200px;
}

.login-banner {
    max-width: 640px;
    width: 100%;
    height: auto;
}