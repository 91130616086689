.leaderboard-section {
    padding: 2rem;
    background: #f8f9fa;
  }
  
  .leaderboard-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .leaderboard-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 24px;
  }
  
  .leaderboard-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .leaderboard-winners {
    display: flex;
    justify-content: center;
    flex-direction: row ;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .winner-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 0.3rem;
    padding: 12px;
    border-radius: 10px;
    background: linear-gradient(45deg, #e3e3e3, #f5f5f5);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease-in-out;
    transition: transform 0.3s ease-in-out;
  
    p {
      margin: 0;
      padding: 0;
    }
  }
  
  .winner-container:hover {
    transform: scale(1.05);
  }
  
  .winner-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto;
  }
  
  .winner-1 {
    background: linear-gradient(135deg, #FFEE93, #FFCC80);
    position: relative;
    top: -24px;
  
  }
  
  .winner-1::before {
    content: '1st';
    position: absolute;
    top: -10px;
    left: -10px;
    background: #FFD700;
    color: white;
    padding: 5px 10px;
    border-radius: 50%;
    font-size: 0.8em;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .winner-2 {
    background: linear-gradient(135deg, #B5EAD7, #86E1C6);
    position: relative;
  }
  
  .winner-2::before {
    content: '2nd';
    position: absolute;
    top: -10px;
    left: -10px;
    background: #55C1C1;
    color: white;
    padding: 5px 10px;
    border-radius: 50%;
    font-size: 0.8em;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .winner-3 {
    background: linear-gradient(135deg, #FEB692, #EA5455);
    position: relative;
  }
  
  .winner-3::before {
    content: '3rd';
    position: absolute;
    top: -10px;
    left: -10px;
    background: #FF6B6B;
    color: white;
    padding: 5px 10px;
    border-radius: 50%;
    font-size: 0.8em;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .winner-rank {
    font-size: 28px;
    font-weight: bold;
  }
  
  .winner-name {
    font-size: 20px;
  
  }
  
  .leaderboard-others {
    display: flex;
    justify-content: space-between;
  }
  
  .other-rank {
    font-size: 1.2rem;
    margin: 0.5rem 0;
  }
  
  .leaderboard-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  