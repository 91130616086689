@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;600;700&display=swap");

.game-guess-picture {
  .guess-picture-page {
    background-image: radial-gradient(circle at 50% 34%, #13745a, #074535 122%);
  }

  .games-page-circle {
    img {
      max-width: 122px;
      width: 100%;
    }
  }

  .wrapper-wp-start {
    font-family: "Lexend", sans-serif !important;

    h2 {
      font-size: 36px;
      font-weight: 700;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      margin: 0 !important;
    }

    button {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .hangman-competition {
    font-family: "Lexend", sans-serif !important;

    button {
      font-size: 16px;
      font-weight: 600;
      color: #fff;

      &.btn-new-letters {
        background: transparent;
        max-width: 238px;
        width: 100%;
        height: 56px;
        line-height: 56px;
        margin: 0;
        padding: 0;
        border: solid 2px #fff;
        -webkit-border-radius: 8px !important;
        -moz-border-radius: 8px !important;
        border-radius: 8px !important;
        box-shadow: none;
        outline: 0;
        margin-right: 24px;

        &:hover,
        &:active,
        &:focus,
        &:visited {
          border: solid 2px #fff !important;
          background: transparent !important;
          box-shadow: none !important;
        }

        span {
          margin-left: 6px;
        }
      }
    }

    .hangman-information {
      h2 {
        color: #fff;
        font-size: 36px;
        font-weight: bold;
        line-height: 1.33;
      }
    }

    .hangman-answer {
      max-width: 700px;

      .form-group {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        input.guessLetter {
          transition: none;
          border-top-left-radius: 8px !important;
          border-bottom-left-radius: 8px !important;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          text-transform: uppercase;
          font-size: 36px;
          font-weight: bold;
          line-height: 1.33;
          text-align: center;
        
          &.text-danger {
            border: solid 2px #bc2727 !important;
            color: #bc2727 !important;
          }

          &.text-success {
            border: solid 2px #54ae4b !important;
            color: #54ae4b !important;
          }

          &:focus, &:active {
            border: 0;
            box-shadow: none;
          }
        }

        button.btn.btn-success, button.btn.btn-info, button.btn.btn-danger, button.btn.btn-warning {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          max-width: 178px;
          width: 100%;
          padding-left: 16px;
          padding-right: 16px;
        }

        button.btn.btn-danger {
          background-color: #bc2727 !important;
          border-color: #bc2727 !important;
        }
      }
    }

    .hangman-letters {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 80px 0 110px 0;

      .hangman-letters-items {
        margin-right: 48px;

        &:last-child {
          margin-right: 0;
        }

        input {
          width: 48px;
          height: 56px;
          margin: 0 8px;
          border-radius: 8px;
          background-color: #fff;
          border: 0;
          font-family: "Lexend", sans-serif !important;
          font-size: 36px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: #000;
          text-align: center;
          outline: 0;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          &:hover,
          &:active {
            box-shadow: none;
            outline: 0;
            border: 0;
          }
        }
      }
    }

    .hangman-guess-letters {
      h5 {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.33;
        color: #fff;
        margin: 0;
        padding: 0;
      }

      ul {
        list-style-type: none;
        margin: 24px 0 36px 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        align-items: center;

        li {
          list-style: none;
          margin-right: 6px;
          color: #fff;
          font-size: 36px;
          font-weight: bold;
          line-height: 1.33;
        }
      }
    }
  }
  
  button.btn.btn-success, button.btn.btn-info, button.btn.btn-danger, button.btn.btn-warning {
    max-width: 150px;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px !important;
  }

  .isComplete {
    margin-top: 2rem;
  }
  
  .wrapper-question-start {
    width: 100%;
    max-width: 905px;
    margin: 0 auto;
    min-height: 100%;
    display: flex;
    flex-direction: row;
  
    .wrapper-question {
      padding: 30px;
    }
  
    .wrapper-puzzle {
      width: 100%;
      min-width: 420px;
      padding: 30px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @media screen and (max-width: 767px) {
        width: 100%;
        min-width: 420px;
        padding: 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
  
      div.puzzle-box {
        width: 120px;
        height: 120px;
        background-color: #222;
        border: 1px #f8f8f8 solid;

        @media screen and (max-width: 767px) {
          width: 100px;
          height: 100px;
        }
      }
  
      img {
        user-select: none;
        max-width: 118px;
  
        &:nth-child(4) {
          margin-top: -49px;
        }
  
        &:nth-child(3n+4) {
          clear: both;
        }
      }
    }
  }
}